import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import StarredLink from '../components/starred-link/starred-link'
import HeroImg from '../assets/images/hero-landing-validate.png'

const query = graphql`
    query {
        contentfulMainPage {
            servicesTitle
            servicesSubtitle
            servicesItems {
                id
                title
                description
                shortDescription
                link
            }
        }
    }
`

const Content = ({ location }) => {
    const currentLocation =
        typeof window !== 'undefined' ? window.location.pathname : ''
    const currentLink = currentLocation.replace(/^\/+|\/+$/g, '')
    const data = useStaticQuery(query)
    const otherServices = data.contentfulMainPage.servicesItems.filter(item => {
        return item.link !== currentLink
    })
    const pageTitle = 'Validate and test a product idea'

    return (
        <Layout>
            <Seo
                title={`${pageTitle} | South Lane`}
                desc="We can help get your product idea to a place where investing time and money into design and development can be done with confidence based on user feedback and performance-based key metrics."
                pathname={location.pathname}
            />
            <div className="content-narrow">
                <div className="heading-container heading-container--how-we-can-help">
                    <p>How we can help</p>
                    <h2>{pageTitle}</h2>
                    <div className="tags-list">
                        <div className="tags-list__item">
                            <div className="tag-item">Discovery</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Ideation & Concept</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Wireframing</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Testing</div>
                        </div>
                    </div>

                    <div className="common-content-block">
                        <p>
                            Taking the time to validate and test a product idea
                            results in being able to move forward based on
                            facts, not assumptions. It prevents you from burning
                            through time, cash, and resources only to find out
                            that your target market doesn&apos;t see the value
                            in what you&apos;ve built.
                        </p>
                    </div>
                </div>
            </div>

            <div className="content-hero-image content-hero-image--landing">
                <img src={HeroImg} alt="Validate and test a product idea" />
            </div>

            <div className="content-narrow">
                <div className="content-blocks-list content-blocks-list--services-landing">
                    <div className="common-content-block">
                        <p>
                            We start with <strong>discovery</strong> to create
                            clarity around unknowns and to make sure we have a
                            complete understanding of your business model,
                            product value, market, and customers. Then
                            prioritize business and user goals through tools
                            like a <strong>value proposition canvas</strong>,{' '}
                            <strong>customer journeys</strong>, and{' '}
                            <strong>user flows</strong>. Finally, we turn these
                            goals into a set of key metrics, design{' '}
                            <strong>wireframes</strong>, and build{' '}
                            <strong>clickable prototypes</strong> that allow
                            real users to move into <strong>testing</strong>{' '}
                            functionality and usability.
                        </p>
                        <p>
                            You may have an idea sketched out on the back of a
                            napkin, or something a little more developed that
                            needs feedback. Wherever it is that you are now, we
                            can help get your product idea to a place where
                            investing time and money into design and development
                            can be done with confidence based on user feedback
                            and performance-based key metrics.
                        </p>
                    </div>
                </div>

                <div className="content-blocks-list content-blocks-list--services-landing-2">
                    <div className="common-content-block">
                        <h6>More ways we can help</h6>
                    </div>
                </div>

                {otherServices.map(item => (
                    <p key={item.link} className="no-font-size">
                        <StarredLink text={item.title} link={item.link} />
                    </p>
                ))}
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
